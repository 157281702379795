<template>
  <div class="tab-btn-box">
    <router-link :to="mypet" class="tab-btn" :class="{ on : path.includes(mypet) }">마이펫</router-link>
    <router-link :to="posts" class="tab-btn" :class="{ on : path === posts }">게시글</router-link>
    <router-link :to="activity" class="tab-btn" :class="{ on : path === activity }">나의활동</router-link>
    <router-link :to="modify" class="tab-btn" :class="{ on : path === modify }">회원정보수정</router-link>
    <router-link :to="password" class="tab-btn" :class="{ on : path === password }" v-if="getTarget === 'email'">비밀번호변경</router-link>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "mypageTab",
  data() {
    return {
      path: this.$route.path,
      mypet: '/mypet',
      posts: '/mypage/posts',
      activity: '/mypage/activity',
      modify: '/mypage/modify',
      password: '/mypage/password',
    }
  },
  computed: {
    ...mapGetters({
      getTarget: 'session/getTarget',
    }),
  },
  methods: {
  }
}
</script>
